<template>
     <v-dialog
        v-model="isQuotaDialogOpen"
        max-width="1100"
         @click:outside="$emit('update:is-quota-dialog-open',false)"
    >
        <v-card
            flat
        >
            <v-card-title>
                Quotas
                <v-spacer></v-spacer>
                <!-- <v-switch
                    v-model="numType"
                    label="123"
                    true-value="count"
                    false-value="percent"
                    hide-details
                >
                <template #prepend>
                    <v-label>%</v-label>
                </template>
                </v-switch> -->
            </v-card-title>
                
            <v-card-text id="quotaRecord">
                <v-data-table 
                    :headers="quotaInformationHeaders"
                    :items="quotaInformation"
                    hide-default-footer
                    hide-default-header
                    disable-pagination
                    disable-sort
                >
                    <template #header="{ }">
                        <thead class="v-data-table-header">
                        <tr>
                            <th 
                                v-for="(h,i) in quotaInformationHeaders"
                                :key="i"
                                class="text-center parent-header td-border-style"
                                :style="`white-space: pre-wrap;`"
                                :rowspan="h.children?1:2"
                                :colspan="h.children?h.children.length:1"
                            >
                            {{ h.text }} <br>
                            <span v-if="h.quota" class="text-caption">N: {{ h.quota }}</span>
                            <v-menu
                                v-model="menu"
                                :close-on-content-click="false"
                                offset-y
                                :transition="false"
                                v-if="h.value == 'actions'"
                                >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon v-bind="attrs" v-on="on">
                                    <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                                    </v-btn>
                                </template>

                                <v-card>
                                    <v-list>
                                        <div v-for="item in quotaInformationHeaders.filter(header => header.value !== 'actions')" :key="item.value" >

                                            <v-list-item v-if="item.value !== 'actions'">
                                                    <v-list-item-content style="cursor: default">
                                                    <v-list-item-title>{{ item.text }}</v-list-item-title>
                                                    </v-list-item-content>
                                                    <v-list-item-action>
                                                            <v-icon
                                                            small
                                                            @click="deleteColumn(item)"
                                                            v-if="!['question', 'choices'].includes(item.value) && (item.children == undefined || item.children.length == 0)"
                                                            >
                                                            {{icons.mdiDelete}}
                                                            </v-icon>
                                                    </v-list-item-action>
                                                
                                            </v-list-item>
                                            <template v-if="item.value !== 'actions' && item.children">
                                                <v-list-item v-for="(h1,i1) in getSubColumn(item.children)"
                                                :key="i1">
                                                    <v-list-item-content style="cursor: default">
                                                    <v-list-item-title>{{ h1.text }}</v-list-item-title>
                                                    </v-list-item-content>
                                                    <v-list-item-action>
                                                            <v-icon
                                                            small
                                                            @click="deleteColumn(item, i1)"
                                                            v-if="item.value !== 'name'"
                                                            >
                                                            {{icons.mdiDelete}}
                                                            </v-icon>
                                                    </v-list-item-action>
                                                </v-list-item>
                                            </template>
                                        
                                        </div>
                                        <v-list-item>
                                            <v-list-item-action>
                                                <v-dialog
                                                    v-model="columnDialog"
                                                    max-width="500px"
                                                    > 
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn
                                                        small
                                                        color="warning"
                                                        dark
                                                        class="mb-2"
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        >
                                                        New Column
                                                        </v-btn>
                                                    </template>
                                                    <v-card>
                                                        <v-card-title>
                                                        <span class="text-h5">Add new column</span>
                                                        </v-card-title>

                                                        <v-card-text>
                                                        <v-container>
                                                            <v-form
                                                                ref="addColumnForm"
                                                            >
                                                            <v-row>
                                                            <v-col
                                                                cols="12"
                                                                sm="12"
                                                                md="12"
                                                            >
                                                                <v-text-field
                                                                    v-model="defaultColumn.text"
                                                                    dense
                                                                    outlined
                                                                    label="Column Title"
                                                                ></v-text-field>
                                                                Select parent column if new column is a subcolumn
                                                                <v-select
                                                                    v-model="defaultColumn.parent"
                                                                    :items="quotaItemHeaders"
                                                                    dense
                                                                    outlined
                                                                    return-object
                                                                >

                                                                </v-select>
                                                                
                                                                <v-text-field
                                                                    v-model="defaultColumn.quota"
                                                                    dense
                                                                    outlined
                                                                    label="Column Sample"
                                                                    type="number"
                                                                    :rules="[validators.required, validators.positiveIntegerValidator]"
                                                                ></v-text-field>
                                                            </v-col>
                                                            
                                                            </v-row>
                                                        </v-form>
                                                        </v-container>
                                                        </v-card-text>

                                                        <v-card-actions>
                                                        <v-spacer></v-spacer>
                                                        <v-btn
                                                            color="blue darken-1"
                                                            text
                                                            @click="closeColumn"
                                                        >
                                                            Cancel
                                                        </v-btn>
                                                        <v-btn
                                                            color="primary"
                                                            text
                                                            type="submit"
                                                            @click.prevent="addColumn"
                                                        >
                                                            Add Column
                                                        </v-btn>
                                                        </v-card-actions>
                                                    </v-card>
                                                    </v-dialog>
                                            </v-list-item-action>
                                        </v-list-item>
                                    </v-list>
                                </v-card>
                                </v-menu>
                            </th>
                        </tr>
                        <tr>
                            <th 
                                v-for="(h1,i1) in getSubHeader(quotaInformationHeaders)"
                                :key="i1"
                                class="text-center child-header td-border-style"
                                :style="`white-space: pre-wrap;`"
                            >
                            {{ h1.text }}
                            <span v-if="h1.quota" class="text-caption">N: {{ h1.quota }}</span>
                            </th>
                        </tr>
                        </thead>
                    </template>

                    <template v-slot:[`item.choices`]="{ item }">
                            <td >
                                <tr v-for="(r, ri) in item.choices" :key="ri">
                                    <span class="text--primary"  >{{ r.text }} </span>
                                </tr>
                            </td>
                    </template>

                    <template v-slot:[`item`]="{ item, index }">
                        <tr>
                            <td v-for="(c,ci) in getRows(item)" :key="ci">
                                <tr v-for="(r, ri) in c" :key="ri">
                                    <template v-if="getQuotaColumns.some(col => ci.startsWith(col))">
                                        <template 
                                            v-if="item[ci] == undefined"
                                        >
                                            <v-edit-dialog
                                                :return-value.sync="item[ci.split('.')[0]][ci.split('.')[2]][ri]"
                                            >
                                                <span class="text--primary"  >{{ r.prefix }} {{ r.value }} </span>
                                                <template v-slot:input>
                                                            <v-select
                                                                v-model="item[ci.split('.')[0]][ci.split('.')[2]][ri].prefix"
                                                                :items="quotaOptions"
                                                                clearable
                                                                dense
                                                                @change="item[ci.split('.')[0]][ci.split('.')[2]][ri].prefix == 'T&B' ? item[ci.split('.')[0]][ci.split('.')[2]][ri].value=null: null"
                                                            >

                                                            </v-select>
                                                            <v-text-field
                                                                v-model="item[ci.split('.')[0]][ci.split('.')[2]][ri].value"
                                                                label="Edit"
                                                                single-line
                                                                dense
                                                                type="number"
                                                                v-if="item[ci.split('.')[0]][ci.split('.')[2]][ri].prefix != 'T&B'"
                                                            ></v-text-field>
                                                </template>
                                            </v-edit-dialog>
                                        </template>
                                        
                                        <v-edit-dialog
                                            :return-value.sync="item[ci][ri]"
                                            v-else
                                        >
                                            <span class="text--primary"  >{{ r.prefix }} {{ r.value }}  </span>
                                            <template v-slot:input>
                                                        <v-select
                                                            v-model="item[ci][ri].prefix"
                                                            :items="quotaOptions"
                                                            clearable
                                                            dense
                                                            class="pt-2"
                                                            @change="item[ci][ri].prefix == 'T&B' ? item[ci][ri].value=null: null"
                                                        >
                                                        </v-select>
                                                        <v-text-field
                                                            v-model="item[ci][ri].value"
                                                            label="Edit"
                                                            single-line
                                                            dense
                                                            type="number"
                                                            v-if="item[ci][ri].prefix != 'T&B'"
                                                        ></v-text-field>
                                            </template>
                                        </v-edit-dialog>
                                    </template>
                                    <template>
                                    <v-edit-dialog
                                            :return-value.sync="item[ci][ri]"
                                            v-if="ci == 'choices'"
                                        >
                                            <span class="text--primary"  >{{ r.text }}  </span>
                                            <template v-slot:input>
                                                    
                                                <span class="text--primary mb-1"  >{{ r.text }}  </span>
                                                <v-select
                                                    v-model="rowPrefix"
                                                    :items="quotaOptions"
                                                    clearable
                                                    dense
                                                    @change="rowPrefix == 'T&B' ? rowValue=null: null"
                                                >
                                                </v-select>
                                                <v-text-field
                                                    v-model="rowValue"
                                                    label="Edit"
                                                    single-line
                                                    dense
                                                    type="number"
                                                    v-if="rowPrefix != 'T&B'"
                                                ></v-text-field>
                                                    <v-btn
                                                        color="primary"
                                                        class="mb-2"
                                                        small
                                                        @click="updateQuotaRow(ri, index)"
                                                        
                                                    >
                                                        Update
                                                    </v-btn>
                                            </template>
                                        </v-edit-dialog>
                                    </template>
                                    <!-- <span class="text--primary"  v-if="ci == 'choices'">{{ r.text }} </span> -->
                                    <span class="text--primary"  v-if="ci == 'question'">{{ r }} </span>
                                </tr>
                            </td>
                           <td>
                            <v-icon
                                small
                                @click="deleteItem(item)"
                            >
                                {{icons.mdiDelete}}
                            </v-icon>
                            </td>
                        </tr>
                    </template>

                    
                    
                </v-data-table>
                
            </v-card-text>
            <v-card-text>
                <v-card>
                    <v-card-text>
                        <v-row>
                            <v-col
                            cols="12"
                            md="11"
                            sm="11"
                            >
                                <v-autocomplete
                                    v-model="quotas"
                                    :items="questionListFiltered"
                                    label="Question"
                                    item-text="name"
                                    dense
                                    outlined
                                    multiple
                                    small-chips
                                    deletable-chips
                                    clearable
                                    hide-details
                                    return-object
                                >

                                </v-autocomplete>
                            </v-col>
                            
                            <v-col
                            cols="12"
                            md="1"
                            sm="1"
                            >
                            <v-btn
                                fab
                                small
                                color="primary"
                                @click="addQuotas"
                                >
                                <v-icon dark>
                                    {{icons.mdiPlus}}
                                </v-icon>
                            </v-btn>
                            
                            </v-col>
                            <v-col
                            cols="12"
                            md="12"
                            sm="12"
                            class="py-0"
                            >

                            <v-btn
                            small
                                color="warning"
                                @click="isCustomQuotaDialogOpen=true"
                            >
                            Add Custom Quota

                            </v-btn>
                            <v-dialog
                                v-model="isCustomQuotaDialogOpen"
                                max-width="500"
                                @click:outside="isCustomQuotaDialogOpen = false"
                            >
                                <v-card
                                >
                                    <v-card-title>Custom Quota</v-card-title>
                                    <v-card-text>
                                        <v-text-field
                                            v-model="customQuota.name"
                                            label="Question"
                                            placeholder="Flash/Eyeflow"
                                            dense
                                            outlined
                                        ></v-text-field>
                                        <v-combobox
                                            v-model="customQuota.choices"
                                            item-text="name"
                                            item-value="name"
                                            hide-selected
                                            small-chips
                                            deletable-chips
                                            label="Choices"
                                            placeholder="Flash"
                                            multiple
                                            dense
                                            outlined
                                            :rules="[validators.required]"
                                            hide-details="auto"
                                            return-object
                                            class="mb-6"
                                            >
                                            <template v-slot:no-data>
                                                <div  class="d-flex align-center">
                                                <v-list-item>
                                                    <v-list-item-content>
                                                    <v-list-item-title>
                                                         Press <kbd>enter</kbd> to add a new choice
                                                    </v-list-item-title>
                                                    </v-list-item-content>
                                                </v-list-item>
                                                </div>
                                            </template>
                                            </v-combobox>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            small
                                            text
                                            @click="isCustomQuotaDialogOpen=false"
                                        >
                                            Cancel
                                        </v-btn>
                                        <v-btn
                                            small
                                            color="success"
                                            @click="addCustomQuota"
                                        >
                                            Add
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                                </v-dialog>
                            </v-col>
                        </v-row>
                        <v-row>
                        </v-row>
                        
                    </v-card-text>
                </v-card>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    text
                >
                    Cancel
                </v-btn>
                <v-btn
                    color="success"
                    :loading="loading"
                    @click="saveQuota"
                >
                    Save
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { ref, watch, computed, getCurrentInstance } from '@vue/composition-api'
import useSurvey from '../../useSurvey'
import { required, positiveIntegerValidator } from '@core/utils/validation'
import _ from 'lodash-es';
import {
    mdiPlus,
    mdiDotsVertical,
    mdiUpdate,
    mdiDelete,
    mdiPencil
} from '@mdi/js'
export default {
    props: {
        isQuotaDialogOpen: {
            type: Boolean,
            required: true,
        },
        projectData: {
            type: Object,
            required: true
        },
        loading: {
            type: Boolean,
            required: true,
        },
    },
    setup(props, {emit}) {
        
        const {
            listQuestionElements
        } = useSurvey()
        
        const menu = ref(false)
        const columnDialog = ref(false)
        
        const defaultColumn = ref({})
        const numType = ref('count')
        const questionsList = ref([])
        const quotas = ref([])
       
        const quotaOptions = ref([
            {text: "Min", fill: true, value:"Min"},
            {text: "Max", fill: true, value:"Max"},
            {text: "T&B", fill: false, value:"T&B"},
        ])
        const rowPrefix = ref("")
        const rowValue = ref("")

        const quotaInformationHeaders = ref([])
        const quotaInformation = ref([])

        const isCustomQuotaDialogOpen = ref(false)
        const customQuota = ref({})
        
        const addColumnForm = ref(null)


        const questionListFiltered = computed(() => {
            return questionsList.value.filter(quest => !quotaInformation.value.map(element => element.question[0]).includes(quest.name))
        })

        watch(
            () => props.isQuotaDialogOpen,
            () => {
                questionsList.value = JSON.parse(JSON.stringify(listQuestionElements(props.projectData.json.pages)))
                quotaInformationHeaders.value = props.projectData.quotas.headers
                quotaInformation.value = props.projectData.quotas.quotas
                customQuota.value = {
                    name: "",
                    choices:[]
                }
            }
        )

        
        const deleteColumn = (item, index=null) => {
            if (index == null) {
                const headerIndex = quotaInformationHeaders.value.indexOf(item)
                quotaInformationHeaders.value.splice(headerIndex, 1)
                quotaInformation.value.map(element => {
                    delete element[item.value]
                })
            } else {
                const headerIndex = quotaInformationHeaders.value.indexOf(item)
                quotaInformationHeaders.value[headerIndex].children.splice(index, 1)
                if (quotaInformationHeaders.value[headerIndex].children.length == 0) {
                    delete quotaInformationHeaders.value[headerIndex].children
                    quotaInformation.value.map(element => {
                        element[item.value] = element[item.value][0]
                    })
                } else {
                    
                    quotaInformation.value.map(element => {
                        element[item.value].splice(index, 1)
                    })
                }
            }
        }
        
        const getMainHeader = (headers) => {
            return headers.filter((i) => i.children);
        }
        const getSubHeader = (headers) => {
            let result = [];
            headers
                .filter((i) => i.children)
                .forEach((v) => {
                result = result.concat(v.children);
                });
            return result;
        }

        const getSubColumn = (column) => {
            let result = [];
            column
                .forEach((v) => {
                result = result.concat(v);
                });
            return result;
        }

        const getQuotaColumns = computed(() => {
            return quotaInformationHeaders.value.filter(header => header.quota != undefined).map(header => header.value)
        })

        // const getRows = (rows) => {
        //     const result = {};
        //     _.forEach(rows, (i, key) => {
        //         if (i.children) {
        //         _.forEach(i.children, (i1, key1) => {
        //             result["c" + key1] = i1;
        //         });
        //         } else result[key] = i;
        //     });
        //     return result;
        // }
        const getRows = (rows) => {
            const result = {};
            _.forEach(rows, (i, key) => {
                if (is2DArray(i)) {
                _.forEach(i, (i1, key1) => {
                    result[key + "." +"c."+ key1] = i1;
                });
                } else result[key] = i;
            });

            const orderedResult = {}
            _.forEach(quotaInformationHeaders.value, (header, index) => {
                if (result.hasOwnProperty(header.value)) {
                    orderedResult[header.value] = result[header.value];
                } else if (header.children && header.children.length > 0) {
                    _.forEach(header.children, (i1, key1) => {
                        orderedResult[header.value + "." +"c."+ key1] = result[header.value + "." +"c."+ key1]
                    });
                }
            })
            return orderedResult;
        }
        const closeColumn = () => {
            defaultColumn.value = {}
            
            addColumnForm.value[0].resetValidation()
            columnDialog.value = false
        }
        
        const addColumn = (index=null) => {
            const isFormValid = addColumnForm.value[0].validate()
            if (isFormValid){
            
                if (index != null) {

                } else {

                    
                }
                // check if new column has a parent column selected if yes then add to children key
                if (defaultColumn.value.parent != undefined) {
                    const index = quotaInformationHeaders.value.findIndex(header => header.value === defaultColumn.value.parent.value);
                    if (quotaInformationHeaders.value[index].children && quotaInformationHeaders.value[index].children.length > 0) {
                        quotaInformationHeaders.value[index].children.push({
                            text: defaultColumn.value.text
                        })
                        quotaInformation.value.map(element => {
                            var arr = []
                            for(var i=0; i< element.choices.length; i++) arr.push({prefix:null, value:0})
                            element[defaultColumn.value.parent.value].push(arr);
                        })
                    } else {
                        quotaInformationHeaders.value[index]['children'] = [{text: defaultColumn.value.text}]
                        quotaInformation.value.map(element => {
                            var arr = []
                            for(var i=0; i< element.choices.length; i++) arr.push({prefix:null, value:0})
                            element[defaultColumn.value.parent.value] = [arr];
                        })
                    }

                } else {
                    let dupName = false
                    let indexLabel = 1

                    while(!dupName){
                        defaultColumn.value.value = `col_${indexLabel}`
                        if(quotaInformationHeaders.value.map(a => a.value).filter(element => element == defaultColumn.value.value).length == 0){
                            dupName = true
                        } else {
                            indexLabel++
                        }
                    }

                    defaultColumn.value.active= true 
                    quotaInformationHeaders.value.splice(-1, 0,defaultColumn.value)
                    
                    quotaInformation.value.map(element => {
                            var arr = []
                            for(var i=0; i< element.choices.length; i++) arr.push({prefix:null, value:0})
                        element[defaultColumn.value.value] = arr
                    })

                }
                
                menu.value = false
                closeColumn()
            }
        }


      const quotaItemHeaders = computed(() => {
            const len = quotaInformationHeaders.value.length - 1
            return quotaInformationHeaders.value.slice(2, len)
        })

        const is2DArray = (arr) => {
            if (!Array.isArray(arr)) {
                return false; // Not an array at all
            }

            // Check if the first element is an array 
            return Array.isArray(arr[0]); 
        }

      
    
        const deleteItem = (item) => {
            const index = quotaInformation.value.findIndex(el => el.question[0] === item.question[0] )
            quotaInformation.value.splice(index, 1)
        }

        const addQuotas = () => {
            quotas.value.map(quota => {
                var question = {
                    question: [quota.name],
                    choices: quota.choices.map(choice => {return {text: choice.text[props.projectData.language.code], value: choice.value}})
                }
                quotaInformationHeaders.value.map(quotaHeader => {
                    if(quotaHeader['quota'] != undefined) {

                        if (quotaHeader['children'] != undefined) {
                            question[quotaHeader['value']] = []
                            quotaHeader['children'].map(child => {
                                var arr = []
                                for(var i=0; i< quota.choices.length; i++) arr.push({prefix:null, value:0})
                                question[quotaHeader['value']].push(arr)
                            })
                        } else {
                            var arr = []
                            for(var i=0; i< quota.choices.length; i++) arr.push({prefix:null, value:0})
                            question[quotaHeader['value']] = arr
                        }
                    }
                })

                quotaInformation.value.push(question)
            })
            quotas.value = []
        }

        const addCustomQuota = () => {
            const normalizedCustomQuota = {
                name: customQuota.value.name
            }
            normalizedCustomQuota['choices'] = customQuota.value.choices.map((choice, index) => {
                return {
                    text: {
                        [props.projectData.language.code]: choice
                    },
                    value: `r${index+1}`
                }
            })

            quotas.value.push(normalizedCustomQuota)
            addQuotas()
            isCustomQuotaDialogOpen.value = false
        }

        const updateQuotaRow = (r, index) => {
            quotaInformationHeaders.value.map(quotaHeader => {
                if(quotaHeader['quota'] != undefined) {

                    if (quotaHeader['children'] != undefined) {
                        quotaHeader['children'].map((child, childIdx) => {
                            quotaInformation.value[index][quotaHeader['value']] = quotaInformation.value[index][quotaHeader['value']].map(subQuota => {
                                subQuota[r].prefix = rowPrefix.value
                                subQuota[r].value = rowValue.value
                                return subQuota
                            })
                        })
                    } else {
                        
                        quotaInformation.value[index][quotaHeader['value']][r] = {
                                prefix: rowPrefix.value,
                                value: rowValue.value
                            }
                    }
                }
            })
            rowPrefix.value = ""
            rowValue.value = ""
        }

        const saveQuota = () => {
            
            emit('save')
        }

        watch(
            isCustomQuotaDialogOpen,
            (newVal, oldVal) => {
                if (!newVal) {
                    customQuota.value = {
                        name:"",
                        choices: []
                    }
                }
            }
        )


        return {
            quotas,
            addQuotas,
            getQuotaColumns,
            deleteColumn,
            quotaInformationHeaders,
            quotaItemHeaders,
            quotaInformation,        
            getSubColumn,
            getSubHeader,
            getRows,
            menu,
            addColumnForm,
            columnDialog,
            defaultColumn,
            closeColumn,
            addColumn,
            deleteItem,
            is2DArray,

            questionsList,
            questionListFiltered,
            quotaOptions,
            numType,
            isCustomQuotaDialogOpen,
            customQuota,
            addCustomQuota,
            updateQuotaRow,
            saveQuota,
            rowPrefix,
            rowValue,

            icons: {
                mdiPlus,
                mdiDotsVertical,
                mdiUpdate,
                mdiDelete,
                mdiPencil
            },

            validators: {
                required,
                positiveIntegerValidator
            }

        }
    }
}
</script>

<style>
.table tbody tr td {
  white-space: pre-wrap
}


</style>

<style lang="sass" scoped>

#quotaRecord
  $record-text-size:14px

  .record-item-value
    font-size: $record-text-size
    border-bottom: 1px solid #a1a1a1
  .record-item-unit
    font-size: $record-text-size
  .record-item-value-border
    border: 1px solid #a1a1a1
    margin-right: 4px

  .record-item-key
    font-weight: bold
    font-size: $record-text-size

  .record-content
    border: 1px solid #a1a1a1
  .v-data-table
    td
      padding: 12px 8px
      font-size: $record-text-size
      border-color: rgba(0,0,0,0.12)
      border-style: solid
      border-left-width: 0px
      border-right-width: 1px
      border-top-width: 0px
      border-bottom-width: 1px
    th
      border-color: rgba(0,0,0,0.12)
      border-style: solid
      border-left-width: 0px
      border-right-width: 1px
      border-top-width: 0px
      border-bottom-width: 1px
  .v-data-table table
    border: solid 1px #a1a1a1
    border-radius: 5px
  .v-data-table table thead tr th
    font-weight: bold
    font-size: $record-text-size
    padding: 0px 8px

  .v-data-table table tbody tr td .v-btn
    margin-right: 0px !important

  .v-data-table .v-data-table-header__sort-badge
    font-size: 10px

  .v-data-table.theme--dark
    tr th
      color: #fff !important

  .theme--light
    .v-data-table table thead tr th
      color: black
    .v-data-table table thead tr td
      color: black
</style>